<template>
  <div class="parent">
    <div class="row margin-0">
      <div class="col-12 pad-0">
        <div class="parent1">
          <div class="parent-head">
            <ul>
              <li :class="{ active: tab === 'About' }" @click="tab = 'About'">
                About
              </li>
            </ul>
          </div>
          <div class="parent-body pad-0">
            <div v-if="tab === 'About'" class="row margin-0">
              <div class="row margin-0">
                <div class="col-12 pad-0">
                  <product-info
                    :product="product"
                    action="NEW_PRODUCT"
                    :mode="null"
                  />
                </div>
                <div class="col-12 pad-0 text-center">
                  <button
                    @click="$emit('edit')"
                    class="btn btn-sm btn-outline-secondary margin-5"
                  >
                    <span class="fa fa-pencil"></span> Edit Ad
                  </button>
                  <template v-if="product.status === 'ACTIVE'">
                    <button
                      @click="updateProduct('SOLDOUT')"
                      class="btn btn-sm btn-outline-success margin-5"
                    >
                      <span class="fa fa-check"></span> Mark as Soldout
                    </button>
                  </template>
                  <template v-else>
                    <button
                      @click="updateProduct('ACTIVE')"
                      class="btn btn-sm btn-outline-primary margin-5"
                    >
                      Make Active
                    </button>
                  </template>
                  <button
                    @click="updateProduct('BLOCKED')"
                    class="btn btn-sm btn-outline-danger margin-5"
                  >
                    Mark Blocked
                  </button>
                  <button
                    @click="updateProduct('VERIFICATION')"
                    class="btn btn-sm btn-outline-warning margin-5"
                  >
                    Make Under Verification
                  </button>
                  <button
                    v-if="!product.featured"
                    @click="updateFeatured(true)"
                    class="btn btn-sm btn-outline-secondary margin-5"
                  >
                    Mark as Featured
                  </button>
                  <button
                    v-if="product.featured"
                    @click="updateFeatured(false)"
                    class="btn btn-sm btn-outline-secondary margin-5"
                  >
                    Mark as end Featured
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductInfo from "./ProductInfo.vue";
export default {
  props: ["product"],
  components: {
    ProductInfo,
  },
  data() {
    return {
      tab: "About",
    };
  },
  methods: {
    updateProduct(status) {
      let product = {...this.product};
      product.status = status;
      this.$cs.enquires
        .update({
          resource: `${this.$cs.enquires.api}${product.id}/`,
          data: product,
        })
        .then((res) => {
          this.$emit('updated');
        });
    },
    updateFeatured(status) {
      let product = {...this.product};
      product.featured = status;
      this.$cs.enquires
        .update({
          resource: `${this.$cs.enquires.api}${product.id}/`,
          data: product,
        })
        .then((res) => {
          this.$emit('updated');
        });
    },
  },
};
</script>