<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12" :class="{'pad-15': !user, 'pad-0': user}">
        <div class="parent margin-bottom-15" v-if="visible_form">
          <div class="parent-body pad-0">
            <div class="row margin-0">
              <div class="col pad-0">
                <h4 class="text-center" v-if="payload.created_on">Update Product</h4>
                <h4 v-else class="text-center">Add New Product</h4>
                <div class="row margin-0 margin-bottom-5">
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Title</b></label>
                      <input
                        type="text"
                        v-model="payload.title"
                        class="form-control form-control-sm"
                        placeholder="Title"
                      />
                      <span class="error" v-if="error && error.title">{{
                        error.title
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Category</b></label>
                      <select name="" id="" v-model="payload.category" class="form-control form-control-sm">
                        <template v-if="cats">
                          <template v-for="(item, idx) in cats" :key="idx">
                            <option :value="item.id">{{item.title}}</option>
                          </template>
                        </template>
                      </select>
                      <span class="error" v-if="error && error.category">{{
                        error.category
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Sub Category</b></label>
                      <select name="" id="" v-model="payload.sub_category" class="form-control form-control-sm">
                        <template v-if="payload.category && subCats && subCats[payload.category]">
                          <template v-for="(item, idx) in subCats[payload.category]" :key="idx">
                            <option :value="item.id">{{item.title}}</option>
                          </template>
                        </template>
                      </select>
                      <span class="error" v-if="error && error.sub_category">{{
                        error.sub_category
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Thumbnail</b></label>
                      <input
                        type="text"
                        v-model="payload.thumbnail"
                        class="form-control form-control-sm"
                        placeholder="thumbnail"
                      />
                      <span class="error" v-if="error && error.thumbnail">{{
                        error.thumbnail
                      }}</span>
                    </div>
                  </div>
                  <!-- <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Order</b></label>
                      <input
                        type="number"
                        v-model="payload.order"
                        class="form-control form-control-sm"
                        placeholder="Order"
                      />
                      <span class="error" v-if="error && error.order">{{
                        error.order
                      }}</span>
                    </div>
                  </div> -->
                  <!-- <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Status</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.status"
                        class="form-control form-control-sm"
                      >
                      <option value="ACTIVE">ACTIVE</option>
                      <option value="ACTIVE">ACTIVE</option>
                      <option value="ACTIVE">ACTIVE</option>
                      </select>
                    </div>
                  </div> -->
                  <div class="col-12 text-right">
                    <button
                      v-if="payload.id"
                      @click="updateData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Update Lookup
                    </button>
                    <button
                      v-else
                      @click="newData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Add New Lookup
                    </button>
                    <button
                      @click="visible_form = false"
                      class="btn btn-light btn-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="parent-body pad-0" v-if="tab==='lookupCode'">
            <div class="row margin-0">
              <div class="col pad-0">
                <h4 class="text-center" v-if="!payload.id">Add New Lookup Code</h4>
                <h4 class="text-center" v-if="payload.id">Update Lookup Code</h4>
                <div class="row margin-0 margin-bottom-5">
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>LOOKUP</b></label>
                      <select name="" id="" v-model="payload.lookup" class="form-control form-control-sm">
                        <template v-for="itm in lookupList" :key="itm.id">
                          <option :value="itm.id">{{itm.id}}</option>
                        </template>
                      </select>
                      <span class="error" v-if="error && error.lookup">{{
                        error.lookup
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>ID</b></label>
                      <input
                        type="text"
                        v-model="payload.id"
                        class="form-control form-control-sm"
                        placeholder="ID"
                      />
                      <span class="error" v-if="error && error.id">{{
                        error.id
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Description</b></label>
                      <input
                        type="text"
                        v-model="payload.title"
                        class="form-control form-control-sm"
                        placeholder="Description"
                      />
                      <span class="error" v-if="error && error.title">{{
                        error.title
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Order</b></label>
                      <input
                        type="number"
                        v-model="payload.order"
                        class="form-control form-control-sm"
                        placeholder="Order"
                      />
                      <span class="error" v-if="error && error.order">{{
                        error.order
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Status</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.status"
                        class="form-control form-control-sm"
                      >
                        <option :value="true">Active</option>
                        <option :value="false">InActive</option>
                      </select>
                    </div>
                  </div>
                  <!-- <div class="col-3">
                    <div class="form-check">
                      <input type="checkbox" v-model="payload.is_default" class="form-check-input" id="exampleCheck1">
                      <label class="form-check-label" for="exampleCheck1">Make as default</label>
                    </div>
                  </div> -->
                  <div class="col-12 text-right">
                    <button
                      v-if="mode===null"
                      @click="newData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Add New Product
                    </button>
                    <button
                      v-else
                      @click="updateData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Update Product
                    </button>
                    <button
                      @click="visible_form = false"
                      class="btn btn-light btn-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="parent">
          <div class="parent-head" v-if="!user">
            <ul>
              <li :class="{ active: tab === 'Courses' }">Products</li>
            </ul>
          </div>
          <div class="parent-body">
            <div class="row margin-0 margin-bottom-5">
              <div class="col-2 pad-0">
                <div class="form-group">
                  <label for=""><b>Search Word</b></label>
                  <input
                    type="text"
                    v-model="search_term"
                    placeholder="Search Word"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <label for=""><b>Status</b></label>
                  <select
                    name=""
                    id=""
                    v-model="status"
                    class="form-control form-control-sm"
                  >
                    <option :value="null">All</option>
                    <option value="VERIFICATION">VERIFICATION</option>
                    <option value="ACTIVE">ACTIVE</option>
                    <option value="INACTIVE">INACTIVE</option>
                    <option value="SOLDOUT">SOLDOUT</option>
                    <option value="BLOCKED">BLOCKED</option>
                  </select>
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <br />
                  <button
                    @click="getdataList()"
                    class="btn btn-primary btn-sm margin-right-10"
                  >
                    Search
                  </button>
                  <button @click="clearFilter();status=null;enquiry_type=null;getdataList()" class="btn btn-light btn-sm">
                    Clear
                  </button>
                </div>
              </div>
              <div class="col text-right">
                <div class="form-group">
                  <br />
                </div>
              </div>
            </div>
            <table class="table sai-table fixed">
              <thead>
                <tr class="t-head">
                  <th>User</th>
                  <th>Title</th>
                  <th
                    :class="{
                      active_sort: sort_by == 'enquiry_type' || sort_by == '-enquiry_type',
                    }"
                    @click="sortBy('enquiry_type')"
                  >
                    Enquiry TYPE
                    <i
                      :class="{
                        'fa-sort': sort_by != 'enquiry_type',
                        'fa-sort-up': sort_by == 'enquiry_type',
                        'fa-sort-down': sort_by == '-enquiry_type',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  
                  <th
                    :class="{
                      active_sort: sort_by == 'status' || sort_by == '-status',
                    }"
                    @click="sortBy('status')"
                  >
                    STATUS
                    <i
                      :class="{
                        'fa-sort': sort_by != 'status',
                        'fa-sort-up': sort_by == 'status',
                        'fa-sort-down': sort_by == '-status',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th>Created on</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading_list">
                  <template v-for="item in dataList.results" :key="item.id">
                    <tr :style="item.featured?{background: '#fff7e4'}:{}">
                      <td @click="
                          selectedProduct === item.id
                            ? (selectedProduct = null)
                            : (selectedProduct = item.id)
                        ">
                        <span
                          class="fa txt-grey"
                          :class="{
                            'fa-chevron-right': selectedProduct !== item.id,
                            'fa-chevron-down': selectedProduct === item.id,
                          }"
                        ></span>
                        {{ item.user?item.user.name:'-' }}</td>
                      <td>{{ item.title }}</td>
                      <td>{{ item.price }}<br/>{{ item.category }} - {{ item.sub_category }}</td>
                      <td><status :status="item.status" /></td>
                      <td>
                        {{$filters.fullDate(item.created_on, 'DD-MMM-YYYY, h:mm A')}}
                        <span
                          class="fa fa-pencil edit-icon margin-left-10 f-right"
                          style="cursor pointer;padding:5px;"
                          @click="dataEdit(item)"
                        />
                      </td>
                    </tr>
                    <tr v-if="selectedProduct === item.id">
                      <td
                        colspan="6"
                        style="background-color: #f5f7fa"
                        class="pad-5"
                      >
                        <product-detail
                          @updated="getdataList()"
                          :product="item"
                        ></product-detail>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
              <footer-table
                :data="dataList"
                :loading="loading_list"
                :pageSize="pageSize"
                @action="handleTableAction"
                cols="6"
              ></footer-table>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductDetail from "./components/ProductDetail";
import DataMixin from "../mixins/dataMixin";
export default {
  props: ['user'],
  components: {
    ProductDetail
  },
  data() {
    return {
      serviceModule: "enquires",
      selectedProduct: null,
      status: null,
      enquiry_type: null,
      defaultPayload: {
        category: null,
        sub_category: null,
        title: null,
        description: null,
        state: null,
        city: null,
        area: null,
        price: 0,
        thumbnail: null,
        images: [],
        ends_on: null,
        data: null,
        contact: {
            whatsapp: false,
            chat: true,
            mobile: true
        },
      },
    };
  },
  mixins: [DataMixin],
  methods: {
    extraParams() {
      let extra = {};
      if(this.status) {
        extra.status = this.status;
      }
      if(this.enquiry_type) {
        extra.enquiry_type = this.enquiry_type;
      }
      if(this.user) {
        extra.user = this.user.id;
      }
      return extra;
    },
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (
        that.payload.description === null ||
        that.payload.description === ""
      ) {
        this.error.description = "Description is required";
        proceed = false;
      }
      if (that.payload.price === null || that.payload.price === "") {
        this.error.price = "Price is required";
        proceed = false;
      }
      if (that.payload.credits === null || that.payload.credits === "") {
        this.error.credits = "Credits is required";
        proceed = false;
      }
      if (that.payload.validity === null || that.payload.validity === "") {
        this.error.validity = "Validity is required";
        proceed = false;
      }
      if (that.payload.order === null || that.payload.order === "") {
        this.error.order = "Order is required";
        proceed = false;
      }
      if (that.payload.title === null || that.payload.title === "") {
        this.error.title = "Title is required";
        proceed = false;
      }
      return proceed;
    },
  },
};
</script>
<style lang="stylus" scoped></style>