<template>
  <div>
    <div class="row margin-0">
      <div class="pad-0" :class="{'col-7':action==='NEW_PRODUCT', 'col-12':action!=='NEW_PRODUCT'}">
        <div class="row margin-0">
          <div class="col-12 pad-left-0" v-if="product.featured">
            <span class="badge badge-warning">Featured</span>
          </div>
          <div class="col-12 pad-left-0">
            <div class="info-block">
              <label for="">Title</label>
              <p>{{ product.title }}</p>
            </div>
          </div>
          <div class="col-4 pad-left-0">
            <div class="info-block">
              <label for="">Price</label>
              <p>{{ product.price }}</p>
            </div>
          </div>
          <div class="col-md-8 pad-left-0">
            <div class="info-block">
              <label for="">Category</label>
              <p>{{ product.category }} - {{ product.sub_category }}</p>
            </div>
          </div>
          <div class="col-12 pad-0">
            <div class="row margin-0">
              <div class="col-4 pad-left-0">
                <div class="info-block">
                  <label for="">Area</label>
                  <p>{{ product.area }}</p>
                </div>
              </div>
              <div class="col-4 pad-left-0">
                <div class="info-block">
                  <label for="">City</label>
                  <p>{{ product.city }}</p>
                </div>
              </div>
              <div class="col-4 pad-left-0">
                <div class="info-block">
                  <label for="">State</label>
                  <p>{{ product.state }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 pad-left-0">
            <div class="info-block">
              <label for="">Description</label>
              <p>{{ product.description }}</p>
            </div>
          </div>
          <div class="col-6 pad-left-0">
            <div class="info-block">
              <label for="">Created On</label>
              <p>
                {{ $filters.fullDate(product.created_on, "DD-MMM-YYYY, h:mm A") }}
              </p>
            </div>
          </div>
          <div class="col-6 pad-left-0">
            <div class="info-block">
              <label for="">Status</label><br>
              <b><status :status="product.status" /></b>
            </div>
          </div>
        </div>
      </div>
      <div class="pad-0" :class="{'col-5':action==='NEW_PRODUCT', 'col-12':action!=='NEW_PRODUCT'}">
        <label for="">Thumbnail &amp; Images</label><br>
        {{product.thumbnail}}
        {{product.images}}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["product", "selections", "mode", "action"],
  data() {
    return {
      checkSelections: [],
    };
  },
  created() {
    if (this.mode === "PARTAIL") {
      // this.checkSelections = this.selections || [];
      this.checkSelections = [];
    } else {
      this.checkSelections = [];
    }
  },
  methods: {
    handleUpdateSelection(event) {
      const that = this;
      this.$nextTick(() => {
        let oldList = [...that.checkSelections];
        if (oldList.indexOf(event.target.id) === -1) {
          oldList.push(event.target.id);
        } else {
        }
        that.$emit("checkboxSelections", oldList);
        that.checkSelections = oldList;
      });
    },
  },
};
</script>